import { PHONE_TYPE_MOBILE } from "@/constants/common";

export default {
  type: "resellers",
  name: null,
  excerpt: "",
  email: null,
  phone: null,
  phone_extension: null,
  phone_type: PHONE_TYPE_MOBILE,
  other_phones: [],
  address: null,
  logo: null,
  relationshipNames: ["owner"],
  owner: {
    type: "users",
    id: null,
  },
};
