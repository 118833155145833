<template>
  <i v-if="checked" class="ni ni-check-bold icon-shape-success" />
  <i v-else class="ni ni-fat-remove icon-shape-danger" />
</template>

<script>
import { Option, Select } from "element-ui";

export default {
  name: "user-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    checked: {
      type: Boolean,
      default: false,
      description: "check success or danger",
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},
};
</script>
